/** @jsx jsx */
import { jsx } from "theme-ui";
import { memo } from "react";

const styleWrap = {
  display: ["block"],
  position: "relative",
  width: "100%",
  height: "250px",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "30px",
}

const styleIframe = {
  position: "absolute",
  width: "inherit",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
}

const Web7skHtml5Banner = ({ isSideCol = "true", ...props }) => {

  return (
    <div sx={{ ...styleWrap, maxWidth: isSideCol ? [null, null, "200px", "300px"] : "300px" }}>
      <iframe
        id={`web7sk-${isSideCol ? "side" : "mob"}`}
        src="/web7sk/index_min.html"
        title="Web7.sk - tvorba web stránok"
        frameBorder="0"
        allowFullScreen={false}
        referrerPolicy="no-referrer"
        height="250"
        style={styleIframe}
      />
    </div>
  )
};
export default memo(Web7skHtml5Banner);
